import './styles/styles.css';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Routes from './routes';
import HeaderNavBar from './components/HeaderNavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderNavBar />
        <Routes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
