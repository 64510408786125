/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Card from '../../components/Card';

import './styles.css';

const Portfolio = () => (
  <div className="portfolio">
    <h3>Projetos desenvolvidos</h3>
    <div className="cards">
      <Card
        imageName="email.png"
        title="Projeto 1"
        description="Projeto desenvolvido para treinar as habilidades"
        link="projeto1"
      />
      <Card
        imageName="logo.png"
        title="Projeto 1"
        description="Projeto desenvolvido para treinar as habilidades"
        link="projeto1"
      />
      <Card
        imageName="logo.png"
        title="Projeto 1"
        description="Projeto desenvolvido para treinar as habilidades"
        link="projeto1"
      />
      <Card
        imageName="logo.png"
        title="Projeto 1"
        description="Projeto desenvolvido para treinar as habilidades"
        link="projeto1"
      />
      <Card
        imageName="teste.png"
        title="Projeto 1"
        description="Projeto desenvolvido para treinar as habilidades"
        link="projeto1"
      />
    </div>
  </div>
);
export default Portfolio;
