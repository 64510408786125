import React, { FormEvent, useState } from 'react';
import './styles.css';

interface IButton {
    // eslint-disable-next-line react/require-default-props
    // eslint-disable-next-line no-unused-vars
    handleClick: {(event: FormEvent): void} | undefined;
            text: string;
    confirmType: boolean;

}

// eslint-disable-next-line no-unused-vars
const Button: React.FC<IButton> = ({ handleClick = undefined, text, confirmType }) => {
  const [borderColor, setBorderColor] = useState('var(--second-color)');
  return (
    <div
      onMouseEnter={() => {
        setBorderColor('black');
      }}
      onMouseLeave={() => {
        setBorderColor('var(--second-color)');
      }}
    >
      {confirmType
        ? (
          <button
            className="button-component"
            type="button"
            onClick={handleClick}
            style={{
              backgroundColor: 'var(--second-color)',
              color: 'white',
              borderColor,
            }}
          >
            {text}
          </button>
        )
        : (
          <button
            className="button-component"
            type="button"
            onClick={handleClick}
            style={{
              backgroundColor: 'white',
              color: 'var(--second-color)',
              borderColor,
            }}
          >
            {text}
          </button>
        )}
    </div>
  );
};

export default Button;
