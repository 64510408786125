/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Article from '../../components/Article';
import './styles.css';

type Props = {
    id: number;
    title: string;
    body: string;
};

const Blog = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const fetchArticles = async () => {
      await api
        .get('http://localhost:3333/blog', { withCredentials: true })
        .then((res) => {
          const articlesList = res.data;
          setArticles(articlesList);
        })
      // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    };
    fetchArticles();
  }, []);
  return (
    <div className="blog">
      {articles.map((article: Props) => (
        <Article
          key={article.id}
          title={article.title}
          body={article.body}
        />
      ))}
    </div>
  );
};
export default Blog;
