/* eslint-disable import/no-anonymous-default-export */
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import api from '../../services/api';

import './styles.css';

const Login: React.FC = () => {
  // eslint-disable-next-line no-unused-vars
  const [jwt, setJwt] = useCookies();
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [password, setPassword] = useState('');

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    switch (event.target.name) {
      case 'login-email':
        setEmail(event.target.value);
        break;
      case 'login-password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    api.post('/login', { email, password })
      .catch((error) => console.log(error));
  };
  return (
    <form className="login">
      <h3>Entrar</h3>
      <div className="form-login-row">
        <label htmlFor="label-email">E-mail:</label>
        <input
          type="email"
          name="login-email"
          id="login-email"
          onChange={handleInputChange}
        />
      </div>
      <div className="form-login-row">
        <label htmlFor="label-password">Senha:</label>
        <input
          type="password"
          name="login-password"
          id="login-password"
          onChange={handleInputChange}
        />
      </div>
      <label htmlFor="button-contact" className="label-form">
        <Link to="/">
          <Button
            handleClick={undefined}
            text="Voltar"
            confirmType={false}
          />
        </Link>

        <Button handleClick={handleSubmit} text="Enviar" confirmType />
      </label>
    </form>
  );
};
export default Login;
