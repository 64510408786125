import React from 'react';
import parse from 'html-react-parser';
import './styles.css';

type ArticleComponent = {
    title: string
     body: string
}
const Article = ({ title, body }: ArticleComponent) => (
  <div className="article">
    <div>
      <h3>{title}</h3>
    </div>
    <div className="description">
      {parse(body)}
    </div>
  </div>
);

export default Article;
