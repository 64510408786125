import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

interface ICard {
    imageName: string;
    title: string;
    description: string;
    link: string;
}

const Card: React.FC<ICard> = ({
  imageName, title, description, link,
}: ICard) => (
  <div className="card">
    <img src={`./images/${imageName}`} alt="" />
    <h5>{title}</h5>
    <p>{description}</p>
    <Link to={`/portfolio/${link}`}>
      <p>visualizar projeto</p>
    </Link>
  </div>
);

export default Card;
