/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import './styles.css';

const About = () => (
  <div className="about">
    <div className="title">
      <h4>Sobre</h4>
      <p className="description">
        Meu nome é Kassuelo Moreira Okaszeski, nasci em 1996 e sempre
        aficionado pela área de tecnologia. Concluí o curso de Técnico
        em Informática no Senac Ijuí-RS, onde adquiri uma base sólida
        acerca das diversas áreas da informática. Posteriormente,
        ingressei no exército em 2015, onde fui promovido à graduação de
        Cabo em 2016 e permaneci até o momento. Simultaneamente com a
        vida militar, em agosto de 2016 dei início à minha vida
        acadêmica, ingressando no curso de Ciência da Computação na
        Unijuí, em Ijuí-RS, de forma que, em 2022, já concluí 73% do
        curso.
      </p>
    </div>
    <div className="title">
      <h4>Nível de conhecimento</h4>
    </div>

    <div className="technologies-group">
      <h5>Tecnologias Back-end</h5>
      <div className="technology">
        <label className="technology-name" htmlFor="nodejs">
          NodeJS
        </label>
        <progress id="nodejs" value="90" max="100">
          90%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="java">
          Java
        </label>
        <progress id="java" value="80" max="100">
          80%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="sql">
          SQL
        </label>
        <progress id="sql" value="70" max="100">
          70%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="typescript">
          TypeScript
        </label>
        <progress id="typescript" value="30" max="100">
          30%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="c-sharp">
          C#
        </label>
        <progress id="c-sharp" value="20" max="100">
          20%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="php">
          PHP
        </label>
        <progress id="php" value="20" max="100">
          20%
        </progress>
      </div>

      <div className="technology">
        <label className="technology-name" htmlFor="python">
          Python
        </label>
        <progress id="python" value="20" max="100">
          20%
        </progress>
      </div>
    </div>
    <div className="technologies-group">
      <h5>Tecnologias Front-end</h5>
      <div className="technology">
        <label className="technology-name" htmlFor="html">
          HTML
        </label>
        <progress id="html" value="90" max="100">
          90%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="css">
          CSS
        </label>
        <progress id="css" value="90" max="100">
          90%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="react">
          React
        </label>
        <progress id="react" value="60" max="100">
          60%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="bootstrap">
          Bootstrap
        </label>
        <progress id="bootstrap" value="50" max="100">
          50%
        </progress>
      </div>
      <div className="technology">
        <label className="technology-name" htmlFor="vuejs">
          Vue.js
        </label>
        <progress id="vuejs" value="40" max="100">
          40%
        </progress>
      </div>
    </div>
  </div>
);

export default About;
