import React, { ChangeEvent, FormEvent } from 'react';
import './styles.css';

import EditorTinyMCE from '../../../components/EditorTinyMCE/EditorTinyMCE';
import Button from '../../../components/Button';

const NewArticle: React.FC = () => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value);
  };

  const handleTinyChange = (text:string): void => {
    console.log(text);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    console.log('enviando');
  };

  return (
    <form className="new-article">
      <label htmlFor="title" className="label-form">
        Título:
        <input type="text" id="title" onChange={handleInputChange} />
      </label>

      <div className="tinymce">
        <EditorTinyMCE onTinyChange={handleTinyChange} />
      </div>
      <label htmlFor="button-publish" className="label-form">
        <Button
          handleClick={handleSubmit}
          text="Voltar"
          confirmType={false}
        />
        <Button
          handleClick={handleSubmit}
          text="Publicar"
          confirmType
        />
      </label>
    </form>
  );
};

export default NewArticle;
