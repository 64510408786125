/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import './styles.css';

const Footer = () => (
  <footer className="footer">
    <p> Copyright © 2022 "Todos os Direitos Reservados"</p>
  </footer>
);
export default Footer;
