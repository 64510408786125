/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import './styles.css';

const Home = () => (
  <div className="home">
    <div className="profile">
      <img src="./images/profile-image.jpg" alt="" />
    </div>
    <div>
      <h3>Olá, eu sou o Kassuelo.</h3>
    </div>
    <div className="description">
      <p>
        O KaDev (Kassuelo Developer) é o website que eu desenvolvi para
        divulgar os projetos em que tenho trabalhado, bem como para
        compartilhar conhecimentos por meio do meu Blog, visando
        contribuir a cada dia mais com a comunidade de desenvolvedores.
      </p>
    </div>
  </div>
);
export default Home;
