import React from 'react';
import { Routes as RoutesSwitch, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Portfolio from './pages/Portfolio';
import NewArticle from './pages/Blog/Article/new';
import Contact from './pages/Contact';
import Login from './pages/Login';

const Routes = () => (
  <RoutesSwitch>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/article/new" element={<NewArticle />} />
    <Route path="/portfolio" element={<Portfolio />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/login" element={<Login />} />
    <Route path="/users" element={null} />
  </RoutesSwitch>
);

export default Routes;
