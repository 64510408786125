/* eslint-disable react/jsx-indent */
import React from 'react';

import { Link } from 'react-router-dom';
import './styles.css';
import Helmet from 'react-helmet';

const HeaderNavBar = () => (
    <nav className="header-nav-bar">
        <div className="logo">
            <Link to="/">
                <img src="./images/logo.png" alt="KaDev" />
            </Link>
        </div>

        <ul className="menu">
            <li>
                <Link to="/">Início</Link>
            </li>
            <li>
                <Link to="/about">Sobre</Link>
            </li>

            <li>
                <Link to="/portfolio">Portfólio</Link>
            </li>
            {/* <li>
                <Link to="/blog">Blog</Link>
            </li>
            */}
            <li>
                <Link to="/blog/article/new">Novo_Artigo</Link>
            </li>
            <li>
                <Link to="/contact">Contato</Link>
            </li>
        </ul>
        <ul className="social-media">
            <li>
                <a
                    href="https://github.com/kassuelo"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="./images/github.png" alt="Github" />
                </a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/in/kassuelo-moreira-okaszeski-bb8429184/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="./images/linkedin.png" alt="LinkedIn" />
                </a>
            </li>
            <li>
                <a
                    href="mailto:kassuelo.mo@hotmail.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="./images/email.png" alt="E-mail" />
                </a>
            </li>
        </ul>
        <div className="hamburger">
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
        </div>
        <Helmet>
            <script type="text/javascript">
                {`
                    const hamburger = document.querySelector(".hamburger");
                    const menu = document.querySelector(".menu");
                    const social = document.querySelector(".social-media");
                    hamburger.addEventListener("click", mobileMenu);

                    function mobileMenu() {
                        hamburger.classList.toggle("active");
                        menu.classList.toggle("active");
                        social.classList.toggle("active");
                    }
                `}
            </script>
        </Helmet>
    </nav>
);
export default HeaderNavBar;
