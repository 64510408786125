/* eslint-disable import/no-anonymous-default-export */
import React, { ChangeEvent, FormEvent } from 'react';
import Button from '../../components/Button';

import './styles.css';

const Contact: React.FC = () => {
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    console.log(event.target.value);
  };
  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    console.log(event.target.value);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    console.log('enviando');
  };
  return (
    <form className="contact">
      <h3>Contato</h3>
      <div className="form-row">
        <label htmlFor="label-name">Nome:</label>
        <input
          type="text"
          name="contact-name"
          id="contact-name"
          onChange={handleInputChange}
        />
      </div>
      <div className="form-row">
        <label htmlFor="label-email">E-mail:</label>
        <input
          type="email"
          name="contact-email"
          id="contact-email"
          onChange={handleInputChange}
        />
      </div>
      <div className="form-row">
        <label htmlFor="label-message">Mensagem:</label>
      </div>
      <div className="form-row">
        <textarea
          name="contact-message"
          id="contact-message"
          cols={30}
          rows={10}
          onChange={handleTextAreaChange}
        />
      </div>
      <label htmlFor="button-contact" className="label-form">
        <Button
          handleClick={handleSubmit}
          text="Voltar"
          confirmType={false}
        />
        <Button handleClick={handleSubmit} text="Enviar" confirmType />
      </label>
    </form>
  );
};
export default Contact;
