import React, { useState } from 'react';
import './styles.css';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';

import { Editor } from '@tinymce/tinymce-react';

interface IProps{
    // eslint-disable-next-line no-unused-vars
    onTinyChange(text: string): void;
}

const EditorTinyMCE: React.FC<IProps> = ({ onTinyChange }: IProps) => {
  const [contentEditor, setContentEditor] = useState('');
  // eslint-disable-next-line no-unused-vars
  const handleEditorChange = (content: string) => {
    // eslint-disable-next-line no-console
    setContentEditor(content);
  };
  onTinyChange(contentEditor);
  return (
    <Editor
      initialValue=""
      init={{
        skin: false,
        content_css: './styles.css',
        language: 'pt_BR',
        language_url: '/langs/pt_BR.js',
        height: 400,
        menubar: true,
        plugins: ['link image', 'table paste', 'autoresize'],
        toolbar:
                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        content_style:
                    'body{font-family: Arial, Helvetica, sans-serif;}',
        autoresize_on_init: true,
      }}
      value={contentEditor}
      onEditorChange={handleEditorChange}
    />
  );
};

export default EditorTinyMCE;
